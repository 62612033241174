import React from "react"

import "./RZDHeader.scss"

export default function RZDHeader({ data }) {
  const { images, subtitle } = data
  return (
    <section
      className="rzd-header-container"
      style={{ backgroundImage: `url(${images.bgImage})` }}
    >
      <div className="rzd-header-wrapper">
        <div className="rzd-header-titleBlock">
          <div className="rzd-header-iconContainer">
            <img
              src={images.logo}
              alt="Railway Roads"
              className="rzd-header-logo"
            />
          </div>
          <h1 className="rzd-header-subtitle">{subtitle}</h1>
        </div>
      </div>
    </section>
  )
}
