import { BgSection, Logo, Map, Section } from "../../images/SingleCase/RZD"

const dataForRZD = {
  type: "project",
  smart: true,
  classComponent: "RZD",
  nextLink: "cases/diasoft/",
  prevLink: "cases/ilich-realty/",
  data: {
    title: "Railway Roads",
    subtitle: "Intellectual control system in railway transportation",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "Railway roads company, the owner of the public infrastructure, a significant part of the rolling stock and the most important operator of the rail network. Formed in 2003 on the basis of the Ministry of Railways.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "The load on the railroad magistral is enormous. The need in the new system to improve the operation of the locomotive complex arose. New management tools are needed within the boundaries of the polygons. Therefore, the implementation of the ITS (intelligent transportation system) in the Eastern Poligone required. Afterwards the ideology and model will be integrated to entire network.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "JavaScript",
            "D3.JS",
            "Ext JS",
            "PHP",
            "CSV",
            "JSON",
            "HTML",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 4,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "7 months",
              count: "",
            },
          ],
        },
        {
          type: "Client",
          content: ["Enterprise"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "For the best data visualization we used d3.js which is successor of Protovis framework and allows great control over the final visual result. It allowed us to make high quality image in svg format from gathered data also as build interactive graphics. The screen is used for 18 monitors with expandable projection and with total resolution of 11520х3240 pixels.",
        "The main challenge was to gather data from the railway station and interpret it to required format to make online dashboard possible - we were receiving CSV formatted data and parsed by PHP made application to required format for javascript to interpret this data to SVG format on displays.",
        "Also we integrated weather condition display for each station on the screen. In addition we performed multi-user admin panel for the reporting purposes. For its realization we have chosen ext.js due to it UI libraries, AJAX technology applicability, good work with DOM and other advantages.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Qualitative leap in the management of the locomotive fleet of the test site",
        "Allow dispatchers to see the entire transportation process as a whole",
        "In case of conflict situations in the operational work, ITS will be able to offer the dispatcher several solutions to the problem",
        "Reporting system which allows to control the railway stations in timely manner",
      ],
    },
    images: {
      bgImage: BgSection,
      logo: Logo,
      section2: Map,
      section3: Section,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service Doc",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/video-conference",
      id: "video-conference",
      title: "Video Conference App",
    },
  ],
  link: "/cases/RZD-roads",
}

export default dataForRZD
