import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function RZD({ data }) {
  let i = 0
  return [
    <section className="RZD compose" key={i++}>
      <div className="bgImage">
        <OverviewComponent {...data} />

        <section className="RZDImg image">
          <div className="imageBlock">
            <div className="container">
              <div className="row">
                <img src={data.images.section2} alt="RZD-map" />
              </div>
            </div>
          </div>
        </section>

        <section className="solution">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.solution.title}</h2>
                {data.solution.textFirst.map((item, i) => (
                  <p className="mb-4" key={i}>{item}</p>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="image-solution image fixed">
          <div className="container">
            <div className="row">
              <img
                src={data.images.section3}
                alt="Railway roads"
                className="solution-picture"
              />
            </div>
          </div>
        </section>

        <section className="solution last">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <h2 className="noMarginTop">{data.delivered.title}</h2>
                <ul>
                  {data.delivered.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>,
  ]
}
